import { Row, List, Skeleton, Empty } from "antd";
import { BrandProps } from "./types";
import styles from "../style.module.scss";
import Link from "next/link";
import { useState, useEffect, useContext } from "react";
import { AppContext } from "~/src/libs/context";
const _ = require("lodash");

const BrandItem = ({
  startingChar,
  searchTerm,
  vendors,
  isLoading = false,
  forMixedPallet,
  activeKey,
  setIsLoading
}: BrandProps) => {
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState([]);
  const { brandsList } = useContext<any>(AppContext);


  const setBrandsByChar = () => {
    return vendors && vendors.length > 0
      ? vendors?.filter((brand) => {
          if (startingChar === "#") {
            return brand.brand.match(/^\d/);
          } else {
            return brand.brand.startsWith(startingChar);
          }
        })
      : brandsList?.filter((brand: string) => {
          if (startingChar === "#") {
            return brand.match(/^\d/);
          } else {
            return brand?.toLowerCase().startsWith(startingChar?.toLowerCase());
          }
        });
  };

  useEffect(() => {
      setIsLoading(false)
      setLoading(false);
    if (forMixedPallet) {
      const sortedBrands = setBrandsByChar();
      setResult(sortedBrands);
    } else {
      if (brandsList && brandsList.length > 0) {
        const sortedBrands = setBrandsByChar();
        setResult(sortedBrands);
        setResult((state) => {
          return state;
        });
      }
    }
  }, []);

  useEffect(() => {
    const sortedBrands = setBrandsByChar();
    setResult(sortedBrands);
    setResult((state) => {
      return state;
    });
  }, [vendors, brandsList]);

  useEffect(() => {
    if (searchTerm?.length > 2) {
      setResult((state) => {
        return state;
      });
    } else {
      const sortedBrands = setBrandsByChar();
      setResult(sortedBrands);
      setResult((state) => {
        return state;
      });
    }
  }, [searchTerm]);

  return result && result?.length > 0 ? (
    <Row id={`brand-${startingChar}`}>
      <div className={`mt-30 mb-16 pb-4 ${styles.brandTitleContainer}`}>
        <Skeleton title={false} loading={forMixedPallet ? isLoading : loading}>
          <span className="oxfordBlue font-bolder-h3 lh-md">
            {startingChar}
          </span>
        </Skeleton>
      </div>
      <List
        grid={{ gutter: 24, xs: 2, sm: 2, md: 2, lg: 4, xl: 4, xxl: 4 }}
        dataSource={forMixedPallet ? result :  _.sortBy(result, (i: string) => i?.toLowerCase())}
        className={styles.brandList}
        renderItem={(item: any) => (
          <Skeleton
            title={false}
            loading={forMixedPallet ? isLoading : loading}
          >
            <List.Item key={item.id}>
              <Link
                href={
                  forMixedPallet
                    ? `mixed-pallet-vendor?ordering=delivered_case_price&vendor=${item?.vendor}`
                    : `/product-list/brands/${item}`
                }
              >
                <a
                  className={`font-regular-md lh-sm ${styles.brandName}`}
                >
                  {forMixedPallet ? item?.brand : item}
                </a>
              </Link>
            </List.Item>
          </Skeleton>
        )}
      />
    </Row>
  ) : activeKey !== "-1" ? (
    <Skeleton
      className="mt-20"
      title={false}
      loading={forMixedPallet ? isLoading : loading}
    >
      <div className={`mt-30 mb-16 pb-4 ${styles.brandTitleContainer}`}>
        <Skeleton title={false} loading={forMixedPallet ? isLoading : loading}>
          <span className="oxfordBlue font-bolder-h3 lh-md">
            {startingChar}
          </span>
        </Skeleton>
      </div>
      <Empty
        imageStyle={{
          display: "none",
        }}
        className={`mt-20 ${styles.noDataMsg}`}
        description={<span>{"No data"}</span>}
      />
    </Skeleton>
  ) : null;
};

export default BrandItem;
